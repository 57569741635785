import React, { useContext, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { animated, useSpring, useTrail } from 'react-spring'
import InteractionContext from '../../context/interactionContext'
import data from './data'

interface ITitle {
    title: string
    animation: any
}

interface IAccordion {
    plainY?: number
    forwardRef: React.MutableRefObject<any>
}

const Title = ({ title, animation }: ITitle) => {
    const el: React.MutableRefObject<any> = useRef()

    return (
        <animated.li
            delay={1500}
            className="overflow-hidden relative block"
            style={animation}
            ref={el}
        >
            <h2 className="font-pxgrotesk text-2xl md:text-6xl">{title}</h2>
        </animated.li>
    )
}

const Accordion = ({ forwardRef }: IAccordion) => {
    const isSmall = useMediaQuery({ query: '(max-width: 768px)' })
    const isMedium = useMediaQuery({ query: '(max-width: 1024px)' })
    const [moveBy, setMoveBy] = useState(0)
    const [currentElement, setCurrentElement] = useState(null)
    const context = useContext(InteractionContext)

    const [trail, set] = useTrail(data.length, () => ({
        transform: 'translate3d(0, 0, 0)',
        opacity: 0,
        height: '0px',
    }))

    setTimeout(
        () =>
            set({
                opacity: 1,
                height: isSmall ? '28px' : '68px',
            }),
        300
    )

    const handleClick = (e: any, d: any, slug: any) => {
        const { y } = e.target.getBoundingClientRect()

        const moveByValue =
            moveBy + forwardRef.current.getBoundingClientRect().y - y

        if (e.target.firstChild.data === currentElement) {
            setMoveBy(2)
            setCurrentElement(null)
            context.setOpenBlock(null)
        } else {
            setMoveBy(moveByValue + 2)
            setCurrentElement(e.target.firstChild.data)
            context.setOpenBlock(slug)
            context.setHoverText('close')
        }
    }

    const handleHover = (text: string) => {
        context.setHoverText(text)
    }

    const animateUp = useSpring({
        delay: 1200,
        opacity: 1,
        transform: 'translate3d(0, 0px, 0)',
        from: {
            opacity: 0,
            transform: 'translate3d(0, 50px, 0)',
        },
    })

    const move = useSpring({
        from: {
            top: `0px`,
        },
        to: {
            top: `${moveBy}px`,
        },
    })

    return (
        <animated.div
            className="relative mt-1 w-full lg:w-4/9 hidden lg:block"
            style={move}
        >
            <animated.ul
                className="mt-16 pt-px-2 accordionHover"
                style={animateUp}
            >
                {data.map((item, index) => (
                    <li
                        key={index}
                        className={`block ${
                            item.slug === context.openBlock && `accordionActive`
                        }`}
                    >
                        <button
                            className={`focus:outline-none my-2`}
                            onMouseEnter={() =>
                                handleHover(
                                    context.openBlock === item.slug
                                        ? 'close'
                                        : ''
                                )
                            }
                            onMouseLeave={() => handleHover('')}
                            onClick={(e: any, d: any) =>
                                !isMedium
                                    ? handleClick(e, d, item.slug)
                                    : () => {}
                            }
                        >
                            <Title
                                title={item.title}
                                animation={trail[index]}
                            />
                        </button>
                    </li>
                ))}
            </animated.ul>
        </animated.div>
    )
}

export default Accordion
