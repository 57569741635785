import React from 'react'
import Transition from './transition'
import text from '../../Text/text'

const Coded = () => (
    <Transition>
        <div className="mb-4 overflow-hidden  mr-4 md:mr-10">
            <h3 className={text.H3}>
                Get your product to market faster with our scalable software
                development services.
            </h3>
            <p className={text.P}>
                We are a software development company that helps startups and
                SMEs get their products to market quickly, securely and
                affordably. Our Agile approach is a perfect option for companies
                that want to get started with their project quickly, without the
                need of hiring a team.
            </p>
        </div>
    </Transition>
)

export default Coded
