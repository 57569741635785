import React from 'react'
import Transition from './transition'
import text from '../../Text/text'

const Infrastructure = () => (
    <Transition>
        <div className="mb-4 overflow-hidden mr-4 md:mr-10">
            <h3 className={text.H3}>Security & Speed of tomorrow</h3>
            <p className={text.P}>
                Your product's uptime and reliability is the next biggest
                priority after a successful delivery. We've got this covered
                too, so you can focus on scaling your business.
            </p>
            <div className="md:flex mt-4 md:mt-10">
                <div className="md:w-1/3 md:mr-4">
                    <h4 className={text.H4_DASH}>Serverless</h4>
                </div>
                <div className="md:w-1/3 md:ml-4 mt-4 md:mt-0">
                    <h4 className={text.H4_DASH}>Cloud Computing</h4>
                </div>
                <div className="md:w-1/3 md:ml-4 mt-4 md:mt-0">
                    <h4 className={text.H4_DASH}>Web Hosting</h4>
                </div>
            </div>
            <div className="md:flex mb-4 md:mb-10 md:mt-4">
                <div className="md:w-1/3 md:mr-4">
                    <h4 className={text.H4_DASH}>Decentralised</h4>
                </div>
                <div className="md:w-1/3 md:ml-4 mt-4 md:mt-0">
                    <h4 className={text.H4_DASH}>Audits</h4>
                </div>
                <div className="md:w-1/3 md:ml-4 mt-4 md:mt-0">
                    <h4 className={text.H4_DASH}>DevOps</h4>
                </div>
            </div>
        </div>
    </Transition>
)

export default Infrastructure
