interface Idata {
    title: string
    slug: string
}

const accordionData: Array<Idata> = [
    {
        title: 'Coded',
        slug: 'coded',
    },
    {
        title: 'Development',
        slug: 'development',
    },
    {
        title: 'Workflow',
        slug: 'workflow',
    },
    {
        title: 'Technology',
        slug: 'technology',
    },
    {
        title: 'Infrastructure',
        slug: 'infrastructure',
    },
    {
        title: 'Support',
        slug: 'support',
    },
]

export default accordionData
