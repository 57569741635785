import React from 'react'

interface Props {
    children: any
}

const Plain = ({ children }: Props) => {
    return (
        <div className="h-canvas-mobile bg-white w-full md:h-canvas-desktop pl-1 md:pl-2 lg:overflow-hidden overflow-y-scroll">
            <div className="lg:flex p-4 lg:p-0">{children}</div>
        </div>
    )
}

export default Plain
