export const modalStyles = {
    BOOKING: `
        w-full 
        h-screen 
        relative
        focus:outline-none
        overflow-y-scroll
        md:overflow-hidden
        text-white
    `,
    BOOKING_OVERLAY: `
        fixed
        left-0
        top-0
        right-0
        bottom-0
        z-20
    `,
}

export default modalStyles
