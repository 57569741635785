import React, { useContext } from 'react'
import InteractionContext from '../../../context/interactionContext'
import Coded from './coded'
import Development from './development'
import Infrastructure from './infrastructure'
import Support from './support'
import Technology from './technology'
import Workflow from './workflow'

const Router = () => {
    const { openBlock } = useContext(InteractionContext)
    return (
        <>
            <div className="mt-16 pt-4 w-1/2 max-w-2xl hidden lg:block">
                {openBlock === 'coded' && <Coded />}
                {openBlock === 'development' && <Development />}
                {openBlock === 'workflow' && <Workflow />}
                {openBlock === 'technology' && <Technology />}
                {openBlock === 'infrastructure' && <Infrastructure />}
                {openBlock === 'support' && <Support />}
            </div>
        </>
    )
}

export default Router
