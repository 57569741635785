import React from 'react'
import Transition from './transition'
import text from '../../Text/text'

const Development = () => (
    <Transition>
        <div className="mb-4 overflow-hidden  mr-4 md:mr-10">
            <h3 className={text.H3}>End to end execution of your idea</h3>
            <p className={text.P}>
                We cover all kinds of software needs from simple websites and
                mobile apps to large scale deployments.
            </p>
            <div className="md:flex mt-4 md:mt-10 md:mb-10">
                <div className="md:w-1/2 mr-4">
                    <h4 className={text.H4_DASH}>Landing Pages</h4>
                </div>
                <div className="md:w-1/2 md:ml-4">
                    <h4 className={text.H4_DASH}>Mobile Apps</h4>
                </div>
            </div>
            <div className="md:flex md:mt-10 md:mb-10">
                <div className="md:w-1/2 md:mr-4">
                    <h4 className={text.H4_DASH}>eCommerce</h4>
                </div>
                <div className="md:w-1/2 md:ml-4">
                    <h4 className={text.H4_DASH}>Blockchain</h4>
                </div>
            </div>
            <div className="md:flex md:mt-10 md:mb-10">
                <div className="md:w-1/2 md:mr-4">
                    <h4 className={text.H4_DASH}>Enterprise Integrations</h4>
                </div>
                <div className="md:w-1/2 md:ml-4">
                    <h4 className={text.H4_DASH}>Large Scale Deployments</h4>
                </div>
            </div>
        </div>
    </Transition>
)

export default Development
