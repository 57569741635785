import React from 'react'
import Transition from './transition'
import text from '../../Text/text'

const Support = () => (
    <Transition>
        <div className="mb-4 overflow-hidden mr-4 md:mr-10">
            <h3 className={text.H3}>
                Have some existing code or projects that require continuous
                support?
            </h3>
            <p className={text.P}>
                Whatever the project or a need, we can help you make sure it
                runs safe and sound. From security audits to updates, monitoring
                and maintenance.
            </p>
            <div className="md:flex mt-4 md:mt-10">
                <div className="md:w-1/2 md:mr-4">
                    <h4 className={text.H4_DASH}>Software Updates</h4>
                </div>
                <div className="md:w-1/2 md:ml-4 mt-4 md:mt-0">
                    <h4 className={text.H4_DASH}>Uptime Monitoring</h4>
                </div>
            </div>
            <div className="md:flex mb-4 md:mt-4">
                <div className="md:w-1/2 md:mr-4">
                    <h4 className={text.H4_DASH}>Continous Backups</h4>
                </div>
                <div className="md:w-1/2 md:ml-4 mt-4 md:mt-0">
                    <h4 className={text.H4_DASH}>Security Audits</h4>
                </div>
            </div>
            <div className="md:flex mb-4 md:mb-10 md:mt-4">
                <div className="md:w-1/2 md:mr-4">
                    <h4 className={text.H4_DASH}>Attack Recovery</h4>
                </div>
                <div className="md:w-1/2 md:ml-4 mt-4 md:mt-0">
                    <h4 className={text.H4_DASH}>Scheduled Maintenance</h4>
                </div>
            </div>
        </div>
    </Transition>
)

export default Support
