import React from 'react'
import { animated, useSpring } from 'react-spring'

interface Props {
    children: any
}

const Transition = ({ children }: Props) => {
    const animation = useSpring({
        opacity: 1,
        transform: 'translate3d(0, 0px, 0)',
        from: {
            opacity: 0,
            transform: 'translate3d(0, 50px, 0)',
        },
    })

    return (
        <animated.div style={animation} className="bg-white overflow-hidden">
            {children}
        </animated.div>
    )
}

export default Transition
