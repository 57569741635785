import React from 'react'
import { animated, useSpring } from 'react-spring'
import check from '../images/check.png'

const Success = (className?: string) => {
    const animation = useSpring({
        config: {
            mass: 1.5,
            tension: 180,
            friction: 12,
        },
        opacity: 1,
        transform: 'scale(1)',
        from: {
            opacity: 0,
            transform: 'scale(0.1)',
        },
    })

    return <animated.img className={className} style={animation} src={check} />
}

export default Success
