import { Link } from 'gatsby'
import React from 'react'
import { GitHub, Linkedin } from 'react-feather'
import { useSpring, animated } from 'react-spring'

const Clutch = () => {
    // const rating = useSpring({
    //     delay: 1400,
    //     number: 5.0,
    //     from: {
    //         number: 0,
    //     },
    // })

    const animation = useSpring({
        delay: 1400,
        transform: 'translate3d(0, 0px, 0)',
        opacity: 1,
        from: {
            opacity: 0,
            transform: 'translate3d(0, 50px, 0)',
        },
    })

    return (
        <>
            {/* <div className="text-2xl text-white mt-1 md:mt-8">
                <animated.span>
                    {rating.number.interpolate((val) => val.toFixed(1))}
                </animated.span>
            </div>
            <div className="underline uppercase opacity-80 text-xs text-white">
                Clutch rating
            </div>
            <div className="text-xs text-white opacity-80">from 1 client</div> */}

            <animated.div
                className="flex text-white mt-8 md:mt-4"
                style={animation}
            >
                <a
                    href="https://www.linkedin.com/company/plaincoded/"
                    target="_blank"
                    rel="nofollow noreferrer"
                    className="inline-block p-4 opacity-50 hover:opacity-100 transition duration-300"
                >
                    <Linkedin width="24" height="24" />
                </a>
                <a
                    href="https://www.github.com/plaincoded/"
                    target="_blank"
                    rel="nofollow noreferrer"
                    className="hidden md:inline-block p-4 opacity-50 hover:opacity-100 transition duration-300"
                >
                    <GitHub width="24" height="24" />
                </a>
                <Link
                    to="/privacy-policy"
                    className="inline-block p-4 opacity-50 hover:opacity-100 transition duration-300"
                >
                    Privacy Policy
                </Link>
                <Link
                    to="/careers"
                    className="inline-block p-4 opacity-50 hover:opacity-100 transition duration-300"
                >
                    Careers
                </Link>
            </animated.div>
        </>
    )
}

export default Clutch
