import React from 'react'
import Transition from './transition'
import text from '../../Text/text'

const Workflow = () => (
    <Transition>
        <div className="mb-4 overflow-hidden mr-4 md:mr-10">
            <h3 className={text.H3}>Speed to Market is key</h3>
            <p className={text.P}>
                We use agile development workflow for all projects whether
                continous agile development or project based, our Project
                Manager coordinates all the work required to complete the
                project as efficiently as possible.
            </p>
            <div className="md:flex mt-4 mb-4 md:mt-10 md:mb-10">
                <div className="md:w-1/2 md:mr-4">
                    <h4 className={text.H4_DASH}>Project Based</h4>
                    <div className="ml-8">
                        <p className={text.P}>
                            Perfect model to develop an MVP. We price the
                            project and send the budget for approval. We use our
                            in-house resources or extend them temporarily to
                            deliver your project.
                        </p>
                    </div>
                </div>
                <div className="md:w-1/2 md:ml-4 mt-4 md:mt-0">
                    <h4 className={text.H4_DASH}>Continuous Development</h4>
                    <div className="ml-8">
                        <p className={text.P}>
                            Pay exaclty what you use on a monthly basis with
                            detailed reports. Stay in full control of the budget
                            and the project.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
)

export default Workflow
