import React from 'react'
import Transition from './transition'
import text from '../../Text/text'

interface ITech {
    frontend: Array<string>
    backend: Array<string>
    blockchain: Array<string>
}

const tech: ITech = {
    frontend: [
        'HTML',
        'CSS',
        'React',
        'Angular',
        'React Native',
        'Ionic',
        'Electron',
    ],
    backend: ['PHP', 'Python', 'NodeJs', '.NET', 'Java'],
    blockchain: ['Solidity', 'ERC20 Tokens', 'ERC721 Tokens'],
}

const Technology = () => (
    <Transition>
        <div className="mb-4 overflow-hidden mr-4 md:mr-10">
            <h3 className={text.H3}>Best suited stack for your product</h3>
            <p className={text.P}>
                Every project is evaluated and assessed so we can pick the right
                stack for the job. Currently our talent uses the following
                stack, however, it's not unusual that we extend the stack based
                on a product need.
            </p>
            <div className="md:flex mt-4 mb-4 md:mt-10 md:mb-10">
                <div className="md:w-1/3 md:mr-4">
                    <h4 className={text.H4_DASH}>Front end</h4>
                    <ul className="ml-8 pl-px md:mt-4">
                        {tech.frontend.map((item, index) => (
                            <li className={text.LI} key={index}>
                                {item}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="md:w-1/3 md:ml-4 mt-4 md:mt-0">
                    <h4 className={text.H4_DASH}>Back end</h4>
                    <ul className="ml-8 pl-px md:mt-4">
                        {tech.backend.map((item, index) => (
                            <li className={text.LI} key={index}>
                                {item}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="md:w-1/3 md:ml-4 mt-4 md:mt-0">
                    <h4 className={text.H4_DASH}>Blockchain</h4>
                    <ul className="ml-8 pl-px md:mt-4">
                        {tech.blockchain.map((item, index) => (
                            <li className={text.LI} key={index}>
                                {item}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    </Transition>
)

export default Technology
