import React, { Ref } from 'react'

interface Props {
    forwardRef: Ref<HTMLDivElement>
}

const Plain = ({ forwardRef }: Props) => {
    return (
        <div className="hidden lg:block mt-2 pt-px overflow-hidden">
            <div
                ref={forwardRef}
                className="text-2xl md:text-6xl mt-4 md:mt-8 lg:mt-16 text-white text-right mr-2 md:mr-3"
            >
                Plain
            </div>
        </div>
    )
}

export default Plain
