import React, { ReactElement, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'
import { animated, useChain, useSpring, useTrail } from 'react-spring'
import Accordion from '../components/Accordion'
import accordionData from '../components/Accordion/data'
import Coded from '../components/Accordion/pages/coded'
import Development from '../components/Accordion/pages/development'
import Infrastructure from '../components/Accordion/pages/infrastructure'
import Router from '../components/Accordion/pages/router'
import Support from '../components/Accordion/pages/support'
import Technology from '../components/Accordion/pages/technology'
import Workflow from '../components/Accordion/pages/workflow'
import Brand from '../components/Brand'
import Canvas from '../components/Canvas'
import Clutch from '../components/Clutch'
import Contact from '../components/Contact'
import Layout from '../components/Layout'
import Plain from '../components/Plain'
import SEO from '../components/Seo'

interface Idata {
    title: string
    slug: string
    content: string
    map?: Function
}

const renderContent = (slug: string) => {
    if (slug === 'coded') return <Coded />
    if (slug === 'development') return <Development />
    if (slug === 'workflow') return <Workflow />
    if (slug === 'technology') return <Technology />
    if (slug === 'infrastructure') return <Infrastructure />
    if (slug === 'support') return <Support />
}

const Index = (): ReactElement => {
    const canvasRef: React.MutableRefObject<any> = useRef()
    const trailRef: React.MutableRefObject<any> = useRef()
    const plainRef: React.MutableRefObject<any> = useRef()
    const mobileRef: React.MutableRefObject<any> = useRef()

    const [trail, set] = useTrail(4, () => ({
        opacity: 0,
        transform: 'translate3d(0, 50px, 0)',
    }))

    const animations = {
        slideRight: useSpring({
            ref: canvasRef,
            delay: 300,
            transform: 'translate3d(0%,0,0)',
            from: { transform: 'translate3d(100%,0,0)' },
            onRest: () =>
                set({ opacity: 1, transform: 'translate3d(0, 0px, 0)' }),
        }),
        slideUp: useSpring({
            ref: trailRef,
            opacity: 1,
            transform: 'translate3d(0, 0px, 0)',
            from: {
                opacity: 0,
                transform: 'translate3d(0, 50px, 0)',
            },
        }),
        enterMobile: useSpring({
            ref: mobileRef,
            opacity: 1,
            from: {
                opacity: 0,
            },
        }),
    }

    useChain([canvasRef, trailRef, mobileRef], [0, 1, 2])

    return (
        <>
            <SEO title="White Label Web Development" />
            <Layout>
                <div className="flex w-full">
                    <div className="h-screen w-px-85 sm:w-48 md:w-64 xl:w-96">
                        <div className="h-px-85 md:h-px-140 w-full overflow-hidden flex justify-end">
                            <animated.div style={trail[0]}>
                                <Brand className="mt-8 md:mt-12 w-2/3 md:w-10 md:mr-4 md:pt-1" />
                            </animated.div>
                        </div>
                        <animated.div style={trail[1]}>
                            <Plain forwardRef={plainRef} />
                        </animated.div>
                    </div>
                    <div className="w-full relative">
                        <div className="h-px-85 md:h-px-140 w-full overflow-hidden">
                            <animated.div style={trail[2]}>
                                <h1 className="text-white text-xs md:text-lg mt-6 pt-1 md:mt-10 font-ibmplex font-light">
                                    White Label
                                    <br />
                                    Software Development for Startups and
                                    Agencies
                                </h1>
                            </animated.div>
                        </div>
                        <animated.div style={animations.slideRight}>
                            <Canvas>
                                <Accordion forwardRef={plainRef} />
                                <Router />

                                <animated.div
                                    style={animations.enterMobile}
                                    ref={mobileRef}
                                    className="lg:hidden"
                                >
                                    {accordionData.map(
                                        (item: Idata, index: number) => (
                                            <div key={index} className="mt-10">
                                                <h2 className="font-pxgrotesk text-sm uppercase">
                                                    <span className="text-gradient-4">
                                                        {index === 0
                                                            ? 'Plaincoded'
                                                            : item.title}
                                                    </span>
                                                </h2>
                                                {renderContent(item.slug)}
                                            </div>
                                        )
                                    )}
                                </animated.div>
                            </Canvas>
                        </animated.div>
                        <div className="h-px-85 md:h-px-140 overflow-hidden">
                            <animated.div style={trail[3]}>
                                <Clutch />
                            </animated.div>
                            <Contact />
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index
