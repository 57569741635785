import React, { useState, useRef, useEffect, useContext } from 'react'
import { useSpring, useChain, animated } from 'react-spring'
import { useMediaQuery } from 'react-responsive'
import Modal from 'react-modal'
import modalStyles from './modal'
import Brand from '../Brand'
import { X } from 'react-feather'
import { useForm } from 'react-hook-form'
import InteractionContext from '../../context/interactionContext'
import Success from '../Success'

// interface IGradient {
//     children: any
//     gradient: string
// }

// const Gradient = ({ children, gradient }: IGradient) => (
//     <span
//         className={`inline-block underline border-b-2 font-medium relative ${gradient}`}
//     >
//         {children}
//     </span>
// )

interface IInput {
    children: any
}

const InputGroup = ({ children }: IInput) => (
    <div className="flex mt-4 md:mt-10">
        <div className="plain-gradient pt-px-2 pb-px-2 pl-px-2">
            <div className="bg-plain-gray w-1 h-full"></div>
        </div>
        {children}
        <div className="plain-gradient pt-px-2 pb-px-2 pr-px-2">
            <div className="bg-plain-gray w-1 h-full"></div>
        </div>
    </div>
)

const Contact = () => {
    const [open, setOpen] = useState(false)
    const [sending, setSending] = useState(false)
    const [hover, setHover] = useState(false)
    const [isSent, setIsSent] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const { register, handleSubmit, errors } = useForm()

    const containerRef: React.MutableRefObject<any> = useRef()
    const buttonRef: React.MutableRefObject<any> = useRef()

    const context = useContext(InteractionContext)

    const isSmall = useMediaQuery({ query: '(max-width: 768px)' })

    const handleHover = (text: string) => {
        context.setHoverText(text)
        setHover(!hover)
    }

    const encode = (data) => {
        return Object.keys(data)
            .map(
                (key) =>
                    encodeURIComponent(key) +
                    '=' +
                    encodeURIComponent(data[key])
            )
            .join('&')
    }

    const getPayload = (data, propsData) => {
        return { body: encode({ ...data, ...propsData }) }
    }

    const sendRequest = async (data) =>
        await fetch('/api/contact', {
            method: 'POST',
            headers: {},
            ...getPayload(data, {}),
        })

    const onSubmit = async (data) => {
        console.log(data)
        setSending(true)
        sendRequest(data)
            .then((res) => {
                console.log(res)
                setIsSent(true)
                return res.status
            })
            .then((status) => {
                console.log(status)
                if (status === 200) {
                    setSending(false)
                    setSuccess(true)
                } else {
                    setSending(false)
                    setError(true)
                }
            })
    }

    function closeModal() {
        setOpen(false)
    }

    const animations = {
        openUp: useSpring({
            ref: containerRef,
            transform: isSmall
                ? open
                    ? 'scale(30)'
                    : 'scale(1)'
                : open
                ? 'scale(80)'
                : hover
                ? 'scale(1.3)'
                : 'scale(1)',
            from: {
                transform: open ? 'scale(1)' : 'scale(0.2)',
            },
        }),
        fadeIn: useSpring({
            ref: buttonRef,
            opacity: open ? 0 : 1,
            from: {
                opacity: open ? 1 : 0,
            },
        }),
        fadeInModal: useSpring({
            delay: open ? 300 : 0,
            opacity: open ? 1 : 0,
            from: {
                opacity: open ? 0 : 1,
            },
        }),
        success: useSpring({
            opacity: 1,
            transform: 'translate3d(0, 0px, 0)',
            from: {
                opacity: 0,
                transform: 'translate3d(0, 50px, 0)',
            },
        }),
    }

    useChain([containerRef, buttonRef])

    useEffect(() => {
        Modal.setAppElement('body')
    }, [])

    return (
        <>
            <animated.div
                onMouseEnter={() => handleHover(``)}
                onMouseLeave={() => handleHover(``)}
                className="rounded-full w-20 h-20 absolute right-10 bottom-10 md:right-24 md:bottom-24 p-px-2"
                style={{
                    ...animations.openUp,
                    background:
                        'linear-gradient(327.32deg, #00FFF0 19.54%, #AF2BFF 89.87%, #0012B8 124.71%)',
                }}
            >
                <div className="rounded-full bg-plain-gray h-full w-full">
                    <animated.button
                        onClick={() => {
                            setOpen(!open)
                            setHover(!hover)
                        }}
                        style={animations.fadeIn}
                        className="group font-ibmplex text-xs uppercase tracking-widest text-white text-center w-full h-full focus:outline-none cursor-none"
                    >
                        <span className="">
                            {!open && (
                                <>
                                    Let's
                                    <br />
                                    Talk
                                </>
                            )}
                        </span>
                    </animated.button>
                </div>
            </animated.div>

            <Modal
                isOpen={open}
                onRequestClose={closeModal}
                className={modalStyles.BOOKING}
                overlayClassName={modalStyles.BOOKING_OVERLAY}
            >
                <animated.div
                    style={animations.fadeInModal}
                    className="max-w-xl mx-auto mt-6 md:mt-24"
                >
                    <div className="flex justify-between px-6 md:px-0">
                        <Brand className="p-2 md:p-4" />
                        <button
                            className="p-2 md:p-4"
                            onClick={() => closeModal()}
                        >
                            <X color="white" width="32" height="32" />
                        </button>
                    </div>

                    <div className="mt-10 md:mt-10 px-8">
                        <h2 className="font-pxgrotesk text-3xl">
                            Got a project
                            <br />
                            in mind?
                        </h2>
                        <p className="font-pxgrotesk font-light mt-8 md:text-lg">
                            {/* My name is{' '}
                            <Gradient gradient="text-gradient-1">
                                Kamil Jura.
                            </Gradient>{' '}
                            I'm the founder and CTO of Plaincoded. We're based
                            in{' '}
                            <Gradient gradient="text-gradient-2">
                                Wrocław,
                            </Gradient>{' '}
                            Poland, but work{' '}
                            <Gradient gradient="text-gradient-3">
                                globally.
                            </Gradient> */}
                            We're Plaincoded. Your one stop shop for software
                            development. We're based in Wrocław, Poland but work
                            globally.
                        </p>
                        <a
                            href="tel:+48717353939"
                            className="text-3xl md:text-4xl font-pxgrotesk text-center block underline mt-8"
                        >
                            +48 71 735 39 39
                        </a>

                        <div className="mt-10">
                            {!isSent && (
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <InputGroup>
                                        <div className="w-full font-pxgrotesk px-4 py-4 relative">
                                            <input
                                                type="text"
                                                name="email"
                                                autoComplete="off"
                                                className="bg-transparent block w-full focus:outline-none text-lg"
                                                placeholder="Work e-mail"
                                                ref={register({
                                                    required: true,
                                                })}
                                                required
                                            />
                                            <label
                                                htmlFor="email"
                                                className="block absolute"
                                                style={{ zIndex: -1 }}
                                            >
                                                Work e-mail
                                            </label>
                                        </div>
                                    </InputGroup>
                                    <InputGroup>
                                        <div className="w-full font-pxgrotesk px-4 py-4 relative">
                                            <textarea
                                                name="message"
                                                autoComplete="off"
                                                className="bg-transparent block w-full focus:outline-none text-lg"
                                                placeholder="Message"
                                                ref={register({
                                                    required: true,
                                                })}
                                                required
                                            />
                                            <label
                                                htmlFor="message"
                                                className="block absolute"
                                                style={{ zIndex: -1 }}
                                            >
                                                Message
                                            </label>
                                        </div>
                                    </InputGroup>

                                    <button
                                        onClick={() => handleSubmit}
                                        className={`mt-4 md:mt-8 flex justify-end submitAnimation font-pxgrotesk text-sm uppercase w-full text-right py-4 ${
                                            sending && `sending`
                                        }`}
                                    >
                                        {sending ? 'Sending' : 'Send'}
                                    </button>
                                </form>
                            )}
                            {isSent && (
                                <div className="flex justify-around">
                                    <div className="my-10">
                                        <Success />
                                        <animated.p
                                            style={animations.success}
                                            className="text-center font-pxgrotesk text-lg text-white my-4"
                                        >
                                            Message sent
                                        </animated.p>
                                    </div>
                                </div>
                            )}
                            <div className="h-32 md:mb-0" />
                        </div>
                    </div>
                </animated.div>
            </Modal>
        </>
    )
}

export default Contact
